@font-face {
  font-family: 'linux';
  /* Name of your custom font */
  src: url('./fonts/linux/Ac437_Acer_VGA_8x8-2y.ttf') format('truetype');
  /* Path to your font file */
  font-weight: normal;
  /* You can set this to bold or other values if needed */
  font-style: normal;
  /* You can set this to italic or other styles if needed */
}

@font-face {
  font-family: 'dseg';
  /* Name of your custom font */
  src: url('./fonts/dseg/DSEG7Classic-Regular.ttf') format('truetype');
  /* Path to your font file */
  font-weight: normal;
  /* You can set this to bold or other values if needed */
  font-style: normal;
  /* You can set this to italic or other styles if needed */
}

@font-face {
  font-family: 'c64';
  /* Name of your custom font */
  src: url('./fonts/c64/C64_Pro_Mono-STYLE.ttf') format('truetype');
  /* Path to your font file */
  font-weight: normal;
  /* You can set this to bold or other values if needed */
  font-style: normal;
  /* You can set this to italic or other styles if needed */
}

::selection {
  background-color: red;
  color: black;
}

::-webkit-scrollbar-thumb {
  background-color: #1e1e1e;
  transition: all ease-in-out 0.3s;
}

::-webkit-scrollbar-thumb:hover {
  background-color: white;
  transition: all ease-in-out 0.3s;
}

::-webkit-scrollbar-track {
  fill: white;
}

::-webkit-scrollbar {
  background-color: transparent !important;
  width: 0.4rem;
  height: 0.4rem;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}


body {
  background-color: black;
  margin: 0;
  font-family: 'c64',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'CustomFont', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.box_status {
  opacity: 0;
  transition: all ease-in-out 0.5s;
}

.box_posts {
  opacity: 0;
  transition: all ease-in-out 0.5s;
  margin-top: 0;
}

.box_footer {
  opacity: 0;
  margin-bottom: 3vw;
  transition: all ease-in-out 0.5s;
}


.box_status.show {
  opacity: 1;
  transition: all ease-in-out 0.5s;
}

.box_posts.show {
  opacity: 1;
  transition: all ease-in-out 0.5s;
}

.box_footer.show {
  opacity: 1;
  transition: all ease-in-out 0.5s;
}

.stats {
  margin-bottom: 3rem;
  gap: 1rem;
}

.mktcap {
  position: relative;
  color: red;
  text-shadow: 0px 0px 30px red;
}

@keyframes showmktcap {
  0% {
    color: red;
    text-shadow: 0px 0px 30px red;
    left: 0rem;
  }

  25% {
    left: -1rem;
  }

  50% {
    color: rgb(60, 255, 0);
    text-shadow: 0px 0px 30px rgb(60, 255, 0);
  }

  75% {
    left: 1rem;
  }

  100% {
    color: red;
    text-shadow: 0px 0px 30px red;
    left: 0rem;
  }
}

.mktcap.show {
  animation: showmktcap 0.3s ease-in-out;
}



.mktcapWhite {
  position: relative;
  color: white;
}

@keyframes showmktcapWhite {
  0% {
    color: black;
    left: 0rem;
  }

  25% {
    left: -1rem;
  }

  50% {
    color: rgb(60, 255, 0);
    text-shadow: 0px 0px 30px rgb(60, 255, 0);
  }

  75% {
    left: 1rem;
  }

  100% {
    color: white;
    left: 0rem;
  }
}

.mktcapWhite.show {
  animation: showmktcapWhite 0.3s ease-in-out;
}

.lie {
  position: relative;
  color: red;
  text-shadow: 0px 0px 30px red;
}

.lie.show {
  animation: showmktcap 0.3s ease-in-out;
}

.container_1 {
  width: 100%;
  gap: 25vw;
  margin-top: 3vw;
  flex-direction: row;
}

.container_2 {
  position: absolute;
  margin-top: 3vw;
  width: 100%;
  flex-direction: row;
}

.last_text_placeholder {
  position: relative;
  font-size: 0.75vw;
  width: 80%;
}

.box_text {
  width: 80%;
  margin-bottom: 4vw;
  gap: 0rem;
}

.status {
  font-size: 0.9vw;
  gap: 0.7vw;
}

.posts {
  font-size: 0.75vw;
  gap: 0.7vw;
  max-height: 8vw;
  overflow: hidden;
  line-height: 1vw;
}

.hud {
  min-height: 100vh;
  animation: showHUD 4s ease-in-out;
}

@keyframes showHUD {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ca {
  font-size: 0.7vw;
  margin-top: 0vw;
}

.close {
  width: 1.5vw;
  height: 1.5vw;
  top: 0vw;
  padding: 0.2vw;
  right: 1.3vw;
}

.new {
  font-size: 0.7vw;
  padding: 0.2vw;
}

.shaBox {
  width: 100%;
  margin-top: 0vw;
  gap: 0vw
}

.sha {
  margin-left: 1vw;
  margin-top: 0.5vw;
  font-size: 0.8vw;
  overflow: hidden;
}

.terminal {
  position: relative;
  height: 80%;
  width: 80%;
}

.canvasHeight {
  height: 100vh;
}

.latest_tweet {
  margin-top: -2vw;
}

@media (max-width: 968px) {

  .latest_tweet {
    margin-top: 4vw;
  }

  .canvasHeight {
    min-height: 160vh;
  }

  .box_status {
    margin-top: 57vh;
  }

  .box_posts {
    opacity: 0;
    margin-top: 0vw;
    transition: all ease-in-out 0.5s;
  }

  .last_text_placeholder {
    position: relative;
    font-size: 3vw;
    width: 90%;
  }

  .stats {
    padding-top: 30vw;
    margin-top: -30vw;
    /* background-color: red; */
    margin-bottom: 2rem;
    gap: 0.7rem
  }

  .terminal {
    position: relative;
    height: 90%;
    width: 90%;
  }

  .sha {
    margin-left: 1vw;
    margin-top: 0vw;
    font-size: 2vw;
    overflow: hidden;
  }

  .pol {
    margin-top: 2vw;
  }

  .new {
    font-size: 2.2vw;
    padding: 0.8vw;
  }

  .close {
    width: 1.5vw;
    height: 1.5vw;
    top: -0.2vw;
    padding: 3vw;
    right: 1.3vw;
  }

  .hud {
    min-height: 120vw;
  }

  .ca {
    font-size: 2vw;
    margin-top: 1.5vw;
  }

  .box_text {
    width: 90%;
    gap: 0.5rem
  }

  .status {
    font-size: 4vw;
  }

  .posts {
    margin-top: 0vw;
    font-size: 2.5vw;
    max-height: 55vw;
    gap: 1.5vw;
    line-height: 5vw;
  }

  .container_1 {
    margin-top: 10vw;
    width: 100%;
    flex-direction: column;
  }

  .container_2 {
    margin-top: 5vw;
    position: relative;
    font-size: 2.5vw;
    width: 100%;
    flex-direction: column;
  }
  
}